<template>
  <b-pagination
    ellipsis-text
    first-number
    last-number
    pills
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #prev-text>
      <svg
        fill="none"
        height="8"
        viewBox="0 0 16 8"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.646445 3.54732C0.451183 3.74259 0.451183 4.05917 0.646445 4.25443L3.82843 7.43641C4.02369 7.63167 4.34027 7.63167 4.53553 7.43641C4.73079 7.24115 4.73079 6.92457 4.53553 6.7293L1.70711 3.90088L4.53553 1.07245C4.7308 0.877189 4.7308 0.560606 4.53553 0.365344C4.34027 0.170082 4.02369 0.170082 3.82843 0.365344L0.646445 3.54732ZM16 3.40088L0.999999 3.40088L0.999999 4.40088L16 4.40088L16 3.40088Z"
          fill="#AAA3B1"
        ></path>
      </svg>
    </template>

    <template #ellipsis-text>
      <svg
        fill="none"
        height="3"
        viewBox="0 0 17 3"
        width="17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0957031 1.19775C0.0957031 1.42432 0.166016 1.61768 0.306641 1.77783C0.451172 1.93408 0.664062 2.01221 0.945312 2.01221C1.22266 2.01221 1.43555 1.93408 1.58398 1.77783C1.73242 1.62158 1.80664 1.42822 1.80664 1.19775C1.80664 0.963379 1.73242 0.766113 1.58398 0.605957C1.43945 0.441895 1.22656 0.359863 0.945312 0.359863C0.660156 0.359863 0.447266 0.441895 0.306641 0.605957C0.166016 0.77002 0.0957031 0.967285 0.0957031 1.19775ZM7.30273 1.19775C7.30273 1.42432 7.37305 1.61768 7.51367 1.77783C7.6582 1.93408 7.87109 2.01221 8.15234 2.01221C8.42969 2.01221 8.64258 1.93408 8.79102 1.77783C8.93945 1.62158 9.01367 1.42822 9.01367 1.19775C9.01367 0.963379 8.93945 0.766113 8.79102 0.605957C8.64648 0.441895 8.43359 0.359863 8.15234 0.359863C7.86719 0.359863 7.6543 0.441895 7.51367 0.605957C7.37305 0.77002 7.30273 0.967285 7.30273 1.19775ZM14.5098 1.19775C14.5098 1.42432 14.5801 1.61768 14.7207 1.77783C14.8652 1.93408 15.0781 2.01221 15.3594 2.01221C15.6367 2.01221 15.8496 1.93408 15.998 1.77783C16.1465 1.62158 16.2207 1.42822 16.2207 1.19775C16.2207 0.963379 16.1465 0.766113 15.998 0.605957C15.8535 0.441895 15.6406 0.359863 15.3594 0.359863C15.0742 0.359863 14.8613 0.441895 14.7207 0.605957C14.5801 0.77002 14.5098 0.967285 14.5098 1.19775Z"
          fill="#AAA3B1"
        />
      </svg>
    </template>

    <template #next-text>
      <svg fill="none" height="8" viewBox="0 0 16 8" width="16">
        <path
          d="M15.3536 4.25443C15.5488 4.05917 15.5488 3.74259 15.3536 3.54733L12.1716 0.365347C11.9763 0.170085 11.6597 0.170085 11.4645 0.365347C11.2692 0.560609 11.2692 0.877192 11.4645 1.07245L14.2929 3.90088L11.4645 6.72931C11.2692 6.92457 11.2692 7.24115 11.4645 7.43641C11.6597 7.63168 11.9763 7.63168 12.1716 7.43641L15.3536 4.25443ZM-8.74228e-08 4.40088L15 4.40088L15 3.40088L8.74228e-08 3.40088L-8.74228e-08 4.40088Z"
          fill="#AAA3B1"
        ></path>
      </svg>
    </template>
  </b-pagination>
</template>

<script>
export default {
  name: "AslicePagination"
};
</script>

<style scoped lang="scss">

</style>
